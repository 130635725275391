import React, { useEffect, useState } from 'react'

import { useAccount } from 'wagmi';
import { useWeb3Modal } from '@web3modal/wagmi/react';

import { Dashboard_Form, Community_Form, Refferals_Form, Level_Downline_Form, My_Rank_Form, Recent_Form } from "./Data_Structure.js";
// import { Personal_Form } from "./Data_Structure.js";

import "./backend_core_x.css";
import "./backend_core_x_media-queries.css";

import Tick from "./assets/Tick.png";
import core_1 from './assets/core_1.png';
import Tick_Blank from './assets/Tick_Blank.png';
  
import core_3 from "./assets/core_3.png";

import right_arrow from "./assets/right_arrow.png";
import cross from "./assets/cross.png";

export default function CoreVenture_After_Login(props) {

    const { open } = useWeb3Modal();

    const[CurrentForm, SetCurrentForm] = useState('DASHBOARD');

    return (
        <>
            {/*<!--------Main-Content-------------------------->*/}
            <div class="main-content">
                <div class="menu-header">
                    <div class="back-grid-short-menu">
                        <div class="back-grid-menu-btn-space">
                            <div class="menu-hide-btn"><button>CORE</button></div>
                            <div class="menu-link-btn"><button onClick={() => {SetCurrentForm("DASHBOARD");}}>Dashboard</button></div>
                            <div class="menu-link-btn"><button onClick={() => {SetCurrentForm("COMMUNITY");}}>Community</button></div> 
                        </div>
                        <div class="back-grid-menu-btn-space">
                            <div class="menu-logout-btn"><button onClick={() => {//SetCurrentForm("");
                                open();
                            }}>Log Out</button></div>
                            <div class="menu-link-btn"><button onClick={() => {SetCurrentForm("REFFERALS");}}>Refferals</button></div>
                            <div class="menu-link-btn"><button onClick={() => {SetCurrentForm("DOWNLINE");}}>Downline</button></div>    
                        </div>
                        <div class="back-grid-menu-btn-space">
                            <div class="menu-hide-btn"><button>VENTURE</button></div>
                            <div class="menu-link-btn"><button onClick={() => {SetCurrentForm("MY RANK");}}>My Rank</button></div>
                            <div class="menu-link-btn"><button onClick={() => {SetCurrentForm("RECENT");}}>Recent</button></div>  
                        </div>
                    </div>
                </div>
                    
                {/* <!--------Main-----------------------> */}
                <main>
                    
                    {
                        CurrentForm === "DASHBOARD" ? (
                            <>
                                {/* <!--------Dashboard----Section-----------------------> */}
                                <Dashboard_Form SetLoadingModal={props.SetLoadingModal} showAlert={props.showAlert}/>
                            </>
                        ) : CurrentForm === "COMMUNITY" ? (
                            <>  
                                {/* <!-------Community----Tree------------------------> */}
                                <Community_Form showAlert={props.showAlert}/>
                            </>
                        ) : CurrentForm === "REFFERALS" ? (
                            <>  
                                {/* <!--------Refferals----Section-----------------------> */}
                                <Refferals_Form/>
                            </>
                        ) : CurrentForm === "DOWNLINE" ? (
                            <>  
                                {/* <!-------Level----Income------------------------> */}
                                <Level_Downline_Form/>
                            </>
                        ) :  CurrentForm === "MY RANK" ? (
                            <>
                                {/* <!-------Rank----Income------------------------> */}
                                <My_Rank_Form/>
                            </>
                        ) :  CurrentForm === "RECENT" ? (
                            <>
                                {/* <!-------Recent----Income------------------------> */}
                                <Recent_Form/>
                            </>
                        ) :  (
                            <>
                            
                            </>
                        )
                    }  

                    {/* <!--------------End----Point------------------------------>   */}
                
                </main>
            </div>
        </>
    );
}
